import React, { useCallback, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Prismic from 'prismic-javascript';
import PrismicDOM from 'prismic-dom';
import { withTrans } from '../i18n/withTrans';
import AppLayout from '../components/AppLayout';
import SEO from '../components/seo';
import { Col, Row } from 'antd';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-image-lightbox';

const apiEndpoint = 'https://mattines.cdn.prismic.io/api/v2';

const HowGallery = ({ photos }) => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);

	const openLightbox = useCallback((event, { photo, index }) => {
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);

	photos = photos.map(i => ({
		src: i.img.tmb.url,
		url: i.img.url,
		width: i.img.dimensions.width,
		height: i.img.dimensions.height
	}));

	return (
		<>
			<Gallery photos={photos} onClick={openLightbox} />

			{viewerIsOpen ? (
				<Lightbox
					mainSrc={photos[currentImage].url}
					nextSrc={photos[(currentImage + 1) % photos.length].url}
					prevSrc={photos[(currentImage + photos.length - 1) % photos.length].url}
					onCloseRequest={() => setViewerIsOpen(false)}
					onMovePrevRequest={() => setCurrentImage((currentImage + photos.length - 1) % photos.length)}
					onMoveNextRequest={() => setCurrentImage((currentImage + 1) % photos.length)}
				/>
			) : null}
		</>
	);
};

const HowPage = () => {
	const [ sections, setSections ] = useState([]);
	const [ titleLabels, setTitleLabels ] = useState('');
	const [ imgLabels, setImgLabels ] = useState('');
	const [ subTitleLabels, setSubTitleLabels ] = useState(null);
	const [ labels, setLabels ] = useState([]);
	const [ titlePanels, setTitlePanels ] = useState('');
	const [ imgPanels, setImgPanels ] = useState('');
	const [ subTitlePanels, setSubTitlePanels ] = useState(null);
	const [ panels, setPanels ] = useState([]);

	const data = useStaticQuery(graphql`
	    query {
	      imageHow: file(relativePath: { eq: "how.jpg" }) {
	        childImageSharp {
	          fluid(maxWidth: 1920) {
	            ...GatsbyImageSharpFluid_withWebp
	          }
	        }
	      }
	    }
	`);

	useEffect(() => {
		Prismic.getApi(apiEndpoint).then(
			api => api.getByID('X4WqjxEAACQAHOu2', { lang : 'fr-ch' })
		).then(response => {
			setSections(response.data.section);
			setTitleLabels(response.data.titre_labels.length ? response.data.titre_labels[0].text : '');
			setImgLabels(response.data.legume_image_labels.url ? response.data.legume_image_labels.url : '');
			setSubTitleLabels(response.data.sous_titre_labels.length ? response.data.sous_titre_labels[0].text : null);
			setLabels(response.data.labels);
			setTitlePanels(response.data.titre_panels.length ? response.data.titre_panels[0].text : '');
			setImgPanels(response.data.legume_image_panels.url ? response.data.legume_image_panels.url : '');
			setSubTitlePanels(response.data.sous_titre_panels.length ? response.data.sous_titre_panels[0].text : null);
			setPanels(response.data.panels);
		});
	}, []);

	return (
		<AppLayout>
			<SEO title="Comment ?" />

			<div className="hero-image">
				<Img fluid={data.imageHow.childImageSharp.fluid} />
			</div>

			<div>
				{sections.map((section, key) => (
					<div key={key} className="page-section">
						<div className="section-title">
							<h2>
								{section.titre.length ? section.titre[0].text : ''}
								{section.legume_image.url ? (
									<img src={section.legume_image.url} alt={section.titre.length ? section.titre[0].text : ''} />
								) : null}
							</h2>
							<div className="section-title-line" />
						</div>

						<div className="section-content">
							{section.paragraph.length ? (
								<div className="section-content-text">
									{section.sous_titre.length ? <h3>{section.sous_titre[0].text}</h3> : null}
									<div dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(section.paragraph) }} />
								</div>
							) : null}

							{section.content_image.url ? (
								<div className="section-content-image">
									<img src={section.content_image.url} alt={section.titre.length ? section.titre[0].text : ''} />
								</div>
							) : null}
						</div>
					</div>
				))}

				<div className="page-section">
					<div className="section-title">
						<h2>
							{titleLabels}
							<img src={imgLabels} alt={titleLabels} />
						</h2>
						<div className="section-title-line" />
					</div>

					<div className="section-content">
						<div className="section-content-text">
							{subTitleLabels ? <h3>{subTitleLabels}</h3> : null}
						</div>
					</div>

					<div className="section-list">
						{labels.map((l, i) => (
							<Row key={i} gutter={32} align="middle">
								<Col xs={24} md={8} lg={6}>
									<div className="preview">
										<img src={l.image_label.url ? l.image_label.url : ''} alt={l.titre_label.length ? <h3>{l.titre_label[0].text}</h3> : ''} />
									</div>
								</Col>
								<Col xs={24} md={16} lg={18}>
									{l.titre_label.length ? <h3>{l.titre_label[0].text}</h3> : null}
									{l.content_label.length ? <div className="content" dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(l.content_label) }} /> : null}
								</Col>
							</Row>
						))}
					</div>

					<div className="section-title">
						<h2>
							{titlePanels}
							<img src={imgPanels} alt={titlePanels} />
						</h2>
						<div className="section-title-line" />
					</div>

					<div className="section-content">
						<div className="section-content-text">
							{subTitlePanels ? <h3>{subTitlePanels}</h3> : null}
						</div>
					</div>

					<div className="section-content">
						<HowGallery photos={panels} />
					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default withTrans(HowPage);
